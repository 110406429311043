<template>
  <v-card flat>
    <v-window v-model="window" touchless>
      <v-window-item :value="0">
        <v-card-text class="pt-0">
          <v-row dense>
            <v-spacer></v-spacer>
            <v-btn color="color3 color3Text--text" fab small @click.stop="addNew" class="my-2">
              <v-icon>fas fa-plus</v-icon>
            </v-btn>

          </v-row>
          <v-data-table
            :headers="[
              {text: 'Name', value: 'name'},
              {text: 'Type', value: 'type'},
              {text: 'Last Modified', value: 'dtModified'},
              {text: 'By', value: 'modBy'},
              {value: 'actions', sortable: false, align: 'end'}
            ]"
            :items="templates"
            class="elevation-1"
            pagination.sync="pagination"
            item-key="id"
            :loading="loading"
            show-expand
          >
            <template slot="no-data">
              <v-row dense>
                <v-col cols="12" class="text-center">
                  <v-btn color="color3 color3Text--text" @click.stop="addNew">Add Your First Template</v-btn>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.dtModified`]="{item}">
              {{item.dtModified | unixToLocalDt}}
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-btn
                color="color3"
                text icon
                small
                @click.stop="editTemplate(item)"
              >
                <v-icon>fas fa-pencil</v-icon>
              </v-btn>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td></td>
              <td :colspan="headers.length - 1">
                <template v-if="item.description">
                  <h3>Description:</h3>
                  <div>{{item.description}}</div>
                </template>
                <div class="pt-0 caption grey--text">
                  Created by: {{item.createdBy}} on {{item.dtCreated | unixToLocalDt}}
                </div>
              </td>
            </template>

          </v-data-table>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="1">
        <v-card-text>
          <pool-template-wizard
            :poolTemplate="selectedTemplate"
            :username="username"
            v-if="window === 1"
            @cancel-click="onCancel"
            @saved="onSaved"
          ></pool-template-wizard>
        </v-card-text>
      </v-window-item>

    </v-window>
  </v-card>
</template>

<script>
const PoolTemplateWizard = () => import('@/components/Organization/PoolTemplateWizard.vue')

export default {
  props: ['username'],
  data () {
    return {
      window: 0,
      loading: false,
      templates: [],
      selectedTemplate: null
    }
  },
  methods: {
    addNew () {
      this.selectedTemplate = null
      this.window = 1
    },
    editTemplate (template) {
      this.selectedTemplate = JSON.parse(JSON.stringify(template))
      this.window = 1
    },
    loadTemplates () {
      this.loading = true
      this.$VBL.organization.templates.get(this.username)
        .then(r => {
          this.templates = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    onCancel () {
      this.selectedTemplate = null
      this.window = 0
    },
    onSaved (dto) {
      const i = this.templates.findIndex(f => f.id === dto.id)
      if (i > -1) {
        this.templates.splice(i, 1, dto)
      } else {
        this.templates.push(dto)
      }
      this.window = 0
    }
  },
  mounted () {
    this.loadTemplates()
  },
  components: {
    PoolTemplateWizard
  }
}
</script>

<style>

</style>
